import { formatTimestamp } from 'apps/webapp/src/@core/utils/format';
import { TASK_MODE, TIME_FORMAT } from '@finance-ops/types';
import { Box, Typography, useTheme } from '@mui/material';
import { useAuth } from 'apps/webapp/src/hooks/useAuth';
import { RootState } from 'apps/webapp/src/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { taskModeFromUiMode } from '../utils/taskModeFromUiMode';

interface CommonFooter {
  url?: string;
  displayLastUpdated?: boolean;
  filters?: { [key in string]: any };
  sort?: string;
  displayViewMore?: boolean;
}

const CommonFooter = ({ url = '', displayLastUpdated = true, filters = {}, displayViewMore = true }: CommonFooter) => {
  const lastUpdatedSelect = useSelector((state: RootState) => state.dashboard.aggregation.updatedAt);

  const uiMode = useSelector((state: RootState) => state.dashboard.uiMode);
  const lastUpdated = formatTimestamp(lastUpdatedSelect, TIME_FORMAT.HOURS_12);
  const theme = useTheme();
  const router = useRouter();
  const { user } = useAuth();
  const [mode, setMode] = useState<TASK_MODE>();

  useEffect(() => {
    const taskMode: TASK_MODE | null = taskModeFromUiMode(user?.role?.isInternal ?? false, uiMode);
    if (taskMode) {
      setMode(taskMode);
    }
  }, [user, uiMode]);

  const handleClick = () => {
    if (url) {
      const query = { ...filters };
      if (mode) {
        query.mode = mode;
      }
      router.push({
        pathname: `/${url}`,
        query: query,
      });
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: displayViewMore ? 'space-between' : 'flex-end',
        pt: '10px',
        width: '100%',
      }}
    >
      {url && displayViewMore && (
        <Typography
          onClick={handleClick}
          sx={{
            color: theme.palette.primary.main,
            fontWeight: '500',
            cursor: 'pointer',
          }}
        >
          View more
        </Typography>
      )}
      {displayLastUpdated && (
        <Typography sx={{ color: theme.palette.grey[300], fontSize: '10px', alignItems: 'center', mt: 1.5 }}>
          Updated {lastUpdated}
        </Typography>
      )}
    </Box>
  );
};

export default CommonFooter;
