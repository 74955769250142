import CustomButtonGroup, { CustomButtonGroupVariant } from 'apps/webapp/src/@core/components/custom-buttom-group';
import { AgingSummaryDisplay, DashboardAgingSummary, DashboardAgingSummaryDisplay } from '@finance-ops/types';
import CustomToolTip from 'apps/webapp/src/@core/components/custom-tooltip';
import { formatUSD } from 'apps/webapp/src/@core/utils/format';
import { Box, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import dynamic from 'next/dynamic';

import CommonFooter from './CommonFooter';
import Heading from './Heading';
import Loader from 'apps/webapp/src/@core/components/loader';

const ReactApexChart = dynamic(() => import('react-apexcharts'), { ssr: false });

const AgingSummary = ({ agingSummary, loading }: { agingSummary: DashboardAgingSummary; loading: boolean }) => {
  const theme = useTheme();

  const typeButtons = {
    customers: 'Customers',
    amount: 'Amount',
  };

  const [selectedType, setSelectedType] = useState<string>(typeButtons.amount);
  const [categories, setCategories] = useState<string[]>([]);
  const [agingSummaryData, setAgingSummaryData] = useState<DashboardAgingSummaryDisplay>({
    open: [],
    p2p: [],
    others: [],
    predicted: [],
  });

  const [selectedDpdLte, setSelectedDpdLte] = useState<string>('');
  const [selectedDpdGte, setSelectedDpdGte] = useState<string>('');

  const handleDpdChange = (dpdGte: string, dpdLte: string) => {
    if (dpdGte === selectedDpdGte) {
      setSelectedDpdGte('');
      setSelectedDpdLte('');
    } else {
      setSelectedDpdGte(dpdGte);
      setSelectedDpdLte(dpdLte);
    }
  };

  useEffect(() => {
    const agingBoundaries = agingSummary?.boundaries ?? [];
    const boundaries = [...agingBoundaries, agingSummary.endDefault];

    const categories: any = agingBoundaries.map((boundary: number, idx: number) => {
      return `${boundary.toString()} - ${
        idx === agingBoundaries.length - 1 ? `${agingSummary.endDefault}` : agingBoundaries[idx + 1]?.toString()
      }`;
    });
    categories.push(`${agingSummary.endDefault}+`);

    setCategories(categories);

    const selectedTypeKey = selectedType === typeButtons.customers ? 'count' : 'amount';
    const data: any[] = boundaries.map((boundary, idx) => {
      let data: AgingSummaryDisplay = {
        open: 0,
        p2p: 0,
        others: 0,
        predicted: 0,
      };

      const isFound = agingSummary.info.find(each => each._id.toString() === boundary.toString());

      if (isFound) {
        data = {
          open: isFound.data.find(eachData => eachData.type === 'Open')?.[selectedTypeKey] ?? 0,
          p2p: isFound.data.find(eachData => eachData.type === 'P2P')?.[selectedTypeKey] ?? 0,
          others: isFound.data.find(eachData => eachData.type === 'Others')?.[selectedTypeKey] ?? 0,
          predicted:
            agingSummary?.predicted?.length > 0
              ? agingSummary?.predicted?.find(each => each._id.toString() === boundary.toString())?.[selectedTypeKey]
              : 0,
        };
      }
      return data;
    });
    const finalData = data.reduce(
      (acc, curr: AgingSummaryDisplay) => {
        if (curr) {
          acc.open.push(curr.open);
          acc.p2p.push(curr.p2p);
          acc.others.push(curr.others);
          acc.predicted.push(curr.predicted);
        }
        return acc;
      },
      { open: [], p2p: [], others: [], predicted: [] },
    );
    setAgingSummaryData(finalData);
  }, [agingSummary, selectedType]);

  const series = [
    {
      name: 'Others',
      group: 'aging',
      data: agingSummaryData?.others ?? [],
    },
    {
      name: 'Promise-to-pay',
      group: 'aging',
      data: agingSummaryData?.p2p ?? [],
    },
    {
      name: 'Open Invoices',
      group: 'aging',
      data: agingSummaryData?.open ?? [],
    },
  ];

  const data: { series: any[]; options: ApexOptions } = {
    series:
      selectedType === typeButtons.amount && agingSummary?.predicted?.length > 0
        ? [
            ...series,
            {
              name: 'Predicted',
              group: 'prediction',
              data: agingSummaryData?.predicted ?? [],
            },
          ]
        : series,
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        events: {
          dataPointSelection: (event, chartContext, { seriesIndex, dataPointIndex }) => {
            // Accessing the x-axis categories
            const xAxisCategories = chartContext.w.config.xaxis.categories;
            // Getting the x-axis label for the clicked data point
            const selectedXAxisLabel = xAxisCategories[dataPointIndex];

            if (selectedXAxisLabel) {
              const split = selectedXAxisLabel.split(' - ');
              if (split.length === 2) {
                handleDpdChange(split[0], split[1]);
              } else {
                const split = selectedXAxisLabel.split('+');
                handleDpdChange(split[0], '');
              }
            } else {
              handleDpdChange('', '');
            }
          },
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      dataLabels: {
        enabled: false,
        formatter: (val: number) => {
          return val / 1000 + 'K';
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 1,
          columnWidth: agingSummary?.predicted?.length ? '50%' : '35%',
          // startingShape: 'rounded',
        },
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        labels: {
          formatter: (value: number) => {
            if (selectedType === typeButtons.amount) {
              return formatUSD(value);
            }
            return value.toString();
          },
          offsetX: -10,
        },
      },
      fill: {
        opacity: 1,
      },
      colors: [
        theme.palette.common.black,
        '#C0AAFF',
        theme.palette.primary.main,
        theme.palette.customColors.green,
        theme.palette.primary.main,
      ],
      legend: {
        position: 'top',
        horizontalAlign: 'left',
      },
    },
  };

  return (
    <Box>
      <Box sx={{ pb: '16px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Heading text='Aging Summary' />
            <CustomToolTip text='Aging Summary' />
          </Box>
          <CustomButtonGroup
            buttons={Object.values(typeButtons).map(each => {
              return { key: each, label: each };
            })}
            onSelection={value => {
              setSelectedType(value as string);
            }}
            value={selectedType}
            variant={CustomButtonGroupVariant.primary}
          />
        </Box>
      </Box>
      {loading ? (
        <Loader height={'350px'} />
      ) : (
        <ReactApexChart
          key={selectedType}
          css={{
            '& .apexcharts-svg': {
              backgroundColor: `${theme.palette.background.paper} !important`,
            },
            '& .apexcharts-toolbar': {
              display: 'none',
            },
            '& .apexcharts-tooltip .apexcharts-tooltip-title': {
              background: `${theme.palette.primary.main}!important`,
              borderColor: `${theme.palette.primary.main} !important`,
              color: `${theme.palette.common.white} !important`,
            },
            '& .apexcharts-xaxistooltip': {
              display: 'none !important',
            },
            '& .apexcharts-tooltip': {
              background: '#DCDCFF !important',
              marginTop: '-40px',
            },
            '& .apexcharts-legend': {
              left: 'auto !important',
            },
            '& .apexcharts-legend-marker': {
              borderRadius: '4px !important',
            },
          }}
          options={data.options as ApexOptions}
          series={data.series}
          type='bar'
          height={350}
        />
      )}
      <CommonFooter
        url='customers'
        filters={{
          dpdLte: selectedDpdLte,
          dpdGte: selectedDpdGte,
        }}
        displayViewMore={selectedDpdLte ? true : false}
      />
    </Box>
  );
};

export default AgingSummary;
