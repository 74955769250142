// ** MUI Imports
import Box, { BoxProps } from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = ({ height, sx }: { sx?: BoxProps['sx']; height?: string }) => {
  return (
    <Box
      sx={{
        height: height ?? '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <CircularProgress disableShrink />
    </Box>
  );
};

export default Loader;
