import { TASK_MODE, UI_MODE } from '@finance-ops/types';

export const taskModeFromUiMode = (isInternal = false, uiMode: UI_MODE): TASK_MODE | null => {
  let selectedMode;
  if (isInternal) {
    if (uiMode === UI_MODE.COPILOT) {
      selectedMode = TASK_MODE.COPILOT_INTERNAL;
    } else if (uiMode === UI_MODE.AUTOPILOT) {
      selectedMode = TASK_MODE.AUTOPILOT;
    } else {
      selectedMode = null;
    }
  } else {
    if (uiMode === UI_MODE.COPILOT) {
      selectedMode = TASK_MODE.COPILOT_EXTERNAL;
    } else if (uiMode === UI_MODE.AUTOPILOT) {
      selectedMode = TASK_MODE.COPILOT_INTERNAL;
    } else {
      selectedMode = null;
    }
  }
  return selectedMode;
};
