import ButtonGroup from '@mui/material/ButtonGroup';
import { Box, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect } from 'react';

export interface Button {
  key: string;
  label: string;
}

export enum CustomButtonGroupVariant {
  default = 'default',
  primary = 'primary',
}

interface CustomButtonGroups {
  buttons: Button[];
  onSelection: (value: string) => void;
  defaultValue?: string;
  variant?: CustomButtonGroupVariant;
  value?: string;
}

const CustomButtonGroup = ({
  buttons,
  onSelection,
  defaultValue = '',
  value,
  variant = CustomButtonGroupVariant.default,
}: CustomButtonGroups) => {
  const theme = useTheme();

  const handleButtonClick = (selectedButton: string) => {
    onSelection(selectedButton);
  };

  useEffect(() => {
    if (value) {
      handleButtonClick(value);
    }
  }, [value]);

  useEffect(() => {
    onSelection(value ?? defaultValue);
  }, []);

  return (
    <ButtonGroup
      variant='outlined'
      aria-label='outlined button group'
      sx={{
        borderRadius: '50px',
        boxShadow: '0 3px 5px 2px rgba(195, 195, 195, .3)',
        background: theme.palette.background.default,
        minWidth: 'fit-content',
        maxHeight: variant === CustomButtonGroupVariant.primary ? '33px' : '20px',
      }}
    >
      {buttons.map(button => (
        <Button
          key={button.key + button.label}
          onClick={() => handleButtonClick(button.key)}
          variant='outlined'
          sx={{
            borderRadius: '50px !important',
            padding: '0 5px',
            border: 'none',
            margin: '4px',
            transition: '0.2s',
            background:
              value === button.key
                ? variant === CustomButtonGroupVariant.primary
                  ? theme.palette.primary.main
                  : theme.palette.common.white
                : 'none',
            '&:hover': {
              border: 'none',
              background:
                variant === CustomButtonGroupVariant.primary ? theme.palette.primary.main : theme.palette.common.white,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              fontSize: variant === CustomButtonGroupVariant.primary ? '12px' : '8px',
              color:
                value === button.key
                  ? variant === CustomButtonGroupVariant.primary
                    ? theme.palette.common.white
                    : theme.palette.text.primary
                  : theme.palette.text.secondary,
              alignItems: 'center',
              transition: '0.2s',
              '&:hover': {
                border: 'none',
                color:
                  variant === CustomButtonGroupVariant.primary
                    ? theme.palette.common.white
                    : theme.palette.text.secondary,
              },
            }}
          >
            {button.label}
          </Box>
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default CustomButtonGroup;
