import { Tooltip, useTheme, SxProps } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface CustomToolTip {
  text: string;
  sx?: SxProps<Theme>;
}

const CustomToolTip = ({ text, sx }: CustomToolTip) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Tooltip title={t(text)}>
      <InfoOutlinedIcon sx={{ color: theme.palette.info.dark, fontSize: '16px', ...sx }} />
    </Tooltip>
  );
};

export default CustomToolTip;
